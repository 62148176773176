import React, { useState, useEffect } from 'react'
import './AllTransfers.css'
import ExtrasList from './ExtrasList'
import ReturnTransfer from './ReturnTransfer'
// import DatePicker from 'react-datepicker'
import TextField from '@mui/material/TextField'
import 'react-date-picker/dist/DatePicker.css'
import Stack from '@mui/material/Stack'
function AllTransfers () {
  const lg = localStorage.getItem('log')
  if (lg !== 'yes') {
    window.location.href = '/login'
  }
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [items, setItems] = useState([])
  const userid = localStorage.getItem('AuthUserID')
  const [value, setValue] = useState('')
  console.log(value)
  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch(
      `https://for-corporates.jamtransfer.com/backend/AllTransfers.php?userid=${userid}&datum=${value}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setItems(result)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }, [value])

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (!isLoaded) {
    return <div>Loading...</div>
  } else {
    return (
      <div>
        <Stack component="form" noValidate spacing={3}>
          <TextField
            id="date"
            label="Transfer Date"
            type="date"
            defaultValue="2022-11-14"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Stack>

        {items.map((item) => (
          <div key={item.detailsid + item.orderid + item.MOrderKey}>
            <div>
              <div>
                {item.TransferStatus === 3 && (
                  <h2 className="text-center fs-4 canceledTransfer">
                    CANCELED
                  </h2>
                )}
                {item.TransferStatus === 5 && (
                  <h2 className="text-center fs-4 completedTransfer">
                    COMPLETED
                  </h2>
                )}
                {item.TransferStatus === 1 && (
                  <h2 className="text-center fs-4 activeTransfer">ACTIVE</h2>
                )}
                <a
                  href={'/transferdetails/' + item.DetailsID}
                  className="nodecor"
                >
                  {' '}
                  <div className="alltransfersdiv">
                    <i className="bi bi-signpost-fill"></i>
                    {item.OrderID}-{item.TNo}
                  </div>
                  <div className="alltransfersdiv">
                    <i className="bi bi-alarm-fill"></i>
                    {item.PickupTime} <br />{' '}
                    <i className="bi bi-sign-turn-right-fill"></i>
                    {item.PickupName} {'>>'} {item.DropName}
                  </div>
                  <div className="alltransfersdiv">
                    <i className="bi bi-person-fill"></i>
                    {item.PaxName}
                  </div>
                  <div className="alltransfersdiv">
                    <i className="bi bi-people-fill"></i>
                    {item.PaxNo} passengers ::R{' '}
                    {item.TNo === 1 && (
                      <ReturnTransfer orderid={item.OrderID} />
                    )}
                  </div>{' '}
                </a>
                <div className="alltransfersdiv">
                  <i className="bi bi-telephone-fill"></i>
                  <a className="nodecor" href={'tel:' + item.MPaxTel}>
                    {' '}
                    {item.MPaxTel}{' '}
                  </a>
                </div>
                <div className="alltransfersdiv">
                  <i className="bi bi-airplane-fill"></i>
                  <a
                    className="nodecor"
                    href={`https://for-corporates.jamtransfer.com/backend/Parse.php?flightno=${item.FlightNo}&datum=${item.FlightTime}`}
                  >
                    {' '}
                    {item.FlightNo} {item.FlightTime}{' '}
                  </a>
                </div>
                <div>
                  <ExtrasList detailsid={item.DetailsID} />
                </div>
                <div className="alltransfersdiv">
                  <i className="bi bi-car-front-fill"></i>
                  <a
                    className="nodecor"
                    href={
                      'https://www.google.com/maps/search/' +
                      item.PickupAddress +
                      ',' +
                      item.PickupName
                    }
                  >
                    {' '}
                    {item.PickupAddress} {item.PickupName}{' '}
                  </a>
                </div>
                <div className="alltransfersdiv">
                  <i className="bi bi-cursor-fill"></i>
                  <a
                    className="nodecor"
                    href={
                      'https://www.google.com/maps/search/' +
                      item.DropAddress +
                      ',' +
                      item.DropName
                    }
                  >
                    {' '}
                    {item.DropAddress} {item.DropName}{' '}
                  </a>
                </div>
                <div className="divider"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default AllTransfers
