import React from 'react'
import { useParams } from 'react-router-dom'

function Receipt () {
  const lg = localStorage.getItem('log')
  if (lg !== 'yes') {
    window.location.href = '/login'
  }
  const userid = localStorage.getItem('AuthUserID')
  if (userid < 10) {
    window.location.href = '/'
  }
  const { id } = useParams()

  async function getText (file) {
    const x = await fetch(file)
    const y = await x.text()
    document.getElementById('demo').innerHTML = y
  }
  getText(`https://for-travel-agencies.jamtransfer.com/backend/Racun.php?id=${id}`
  )
  function handleClick () {
    const x = document.getElementById('myDIV')
    if (x.style.display === 'none') {
      x.style.display = 'block'
    } else {
      x.style.display = 'none'
    }

    window.print()
  }
  return (
        <div>
            <div id={'demo'}></div>
            <div id={'myDIV'}>
            <div className="hidee"><a href={'https://test.taxifrom.com/aplikacija-backend/api/' + id + '.txt'}>Download</a>
</div>
            <div><button className="hidee" onClick={handleClick}>Print this page</button>
</div>
        </div>
        </div>
  )
}

export default Receipt
