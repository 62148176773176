import React, { useState, useEffect } from 'react'

function ExtrasList ({ detailsid }) {
  const lg = localStorage.getItem('log')
  if (lg !== 'yes') {
    window.location.href = '/login'
  }
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [items, setItems] = useState([])

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch(`https://for-corporates.jamtransfer.com/backend/ExtrasList.php?detailsid=${detailsid}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setItems(result)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }, [])

  if (error) {
    return <span>Error: {error.message}</span>
  } else if (!isLoaded) {
    return <span>Loading...</span>
  } else {
    return (
      <span>
        {items.map(item => (
          <span key={item.ID}>
           <i className="bi bi-box-fill"></i>
 {item.ServiceName} {item.ServiceQty} <br></br>
           </span>
        ))}
      </span>
    )
  }
}

export default ExtrasList
