import React, { useEffect, useState } from 'react'

function Messages () {
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [items, setItems] = useState([])
  function accept (mid) {
    async function getText (file) {
      const x = await fetch(file)
      const y = await x.text()
      alert(y)
    }
    getText(
      `https://for-corporates.jamtransfer.com/backend/MessageAction.php?mid=${mid}`
    )
    window.location.reload()
  }
  function acceptt (mid) {
    async function getText (file) {
      const x = await fetch(file)
      const y = await x.text()
      alert(y)
    }
    getText(
      `https://for-corporates.jamtransfer.com/backend/MessageAction.php?mid=${mid}`
    )
    window.location.href = `/transferdetails/${mid}`
  }
  const userid = localStorage.getItem('AuthUserID')
  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch(
      'https://for-corporates.jamtransfer.com/backend/Messages.php?userid=' + userid
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setItems(result)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (!isLoaded) {
    return <div>Loading...</div>
  } else {
    return (
      <div>
        {items.map((item) => (
          <div key={item.MessageID}>
            <div className="card">
              <h5 className="card-header">
                {item.SentDate} <br /> {item.Title}{' '}
              </h5>
              <div className="card-body">
              <p>{item.MessageID}</p>
                <p className="card-text">{item.Body}</p>
                <div className="d-grid gap-2 col-6 mx-auto">
                  <button
                    onClick={() => accept(item.MessageID)}
                    className="btn btn-primary"
                    type="button"
                  >
                    Potvrdi
                  </button>
                  <a
                    onClick={() => acceptt(item.OrderDetailsID)}
                    className="btn btn-primary"
                    type="button"
                  >
                    Potvrdi i idi na transfer
                  </a>
                </div>
              </div>
            </div>{' '}
          </div>
        ))}
      </div>
    )
  }
}

export default Messages
