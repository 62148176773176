import React from 'react'
import { useParams } from 'react-router-dom'
import './WelcomeSign.css'
function WelcomeSign () {
  const lg = localStorage.getItem('log')
  if (lg !== 'yes') {
    window.location.href = '/login'
  }
  const { name } = useParams()
  return (
        <div>
            <div className="WelcomeContainer text-center">
  <div className="vertical-center">
    <p>{name}</p>
  </div>
</div>
        </div>
  )
}

export default WelcomeSign
