// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import axios from 'axios'

// import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth'
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc
} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDkXqLRxxvEuy6rxLVg-zgjzQkGQleA1O4',
  authDomain: 'jamtransferpwa.firebaseapp.com',
  databaseURL: 'https://jamtransferpwa-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'jamtransferpwa',
  storageBucket: 'jamtransferpwa.appspot.com',
  messagingSenderId: '711423574984',
  appId: '1:711423574984:web:5c080ebe98ab55cfc3f6fd',
  measurementId: 'G-B0V27BBTXF'
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

const googleProvider = new GoogleAuthProvider()

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    const user = res.user
    const q = query(collection(db, 'users'), where('uid', '==', user.uid))
    const docs = await getDocs(q)
    if (docs.docs.length === 0) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email
      })
    }
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email
    })
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert('Password reset link sent!')
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const logout = () => {
  signOut(auth)
}

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout
}

initializeApp(firebaseConfig)

const messaging = getMessaging()

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: 'BDmLNkp9a-89pnOLD-75BEFAeEtYn7KcoVCqbFhPIdDBfzVwfZ0q4eadU5thwEl_il3yidBEYC1DwY8_GgqoarU' })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken)
        // Perform any other neccessary action with the token

        const userid = localStorage.getItem('AuthUserID')
        const username = localStorage.getItem('AuthUserRealName')

        const width = window.screen.width

        /*   function sendLok() {
            axios.post("https://test.taxifrom.com/backend/crud2.php?token=" + currentToken + "&id= " + userid + "&scwidth=" + width + "&username=" + username)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        })

        } */

        /*   function sendTest () {
          axios.post('https://subdrivers.taxifrom.com/backend/crud2.php?token=' + currentToken + '&id=' + userid + '&scwidth=' + '&username=' + username)
            .then(function (response) {
              console.log(response)
            })
            .catch(function (error) {
              console.log(error)
            })
        } */

        function sendToken () {
        // ?idtokens=&userid=&token=&scwidth=&username=

          axios.post('https://for-corporates.jamtransfer.com/backend/token.php?userid=' + userid + '&token=' + currentToken + '&scwidth=' + width + '&username=' + username)
            .then(function (response) {
              console.log(response)
            })
            .catch(function (error) {
              console.log(error)
            })
        }

        /* function delDupTok() {

          axios.post("https://test.taxifrom.com/backend/removeduptok.php")
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          })

        } */
        /*         setInterval(sendLok, 360);
 */
        sendToken()
        // setInterval(sendToken, 10000);

        // setInterval(delDupTok, 1000);
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.')
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
    })
}

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
