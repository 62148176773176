import React from 'react'
//  import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './Modules/Layout'
import Dashboard from './Modules/Dashboard/Dashboard'
import Login from './Modules/Auth/Login'
// import NoPage from './Modules/NoPage/NoPage'
import AllTransfers from './Modules/Transfers/AllTransfers'
import DayTransfers from './Modules/Transfers/DayTransfers'
import TransferDetails from './Modules/TransferDetails/TransferDetails'
import WelcomeSign from './Modules/TransferDetails/WelcomeSign'
import Finished from './Modules/TransferDetails/Finished'
import Nalog from './Modules/TransferDetails/Nalog'
import Receipt from './Modules/TransferDetails/Receipt'
import Messages from './Modules/Messages/Messages'
export default function App () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="login" element={<Login />} />
          <Route path="alltransfers" element={<AllTransfers />} />
          <Route path="daytransfers/:datum" element={<DayTransfers />} />
          <Route path="transferdetails/:id" element={<TransferDetails />} />
          <Route path="welcome/:name" element={<WelcomeSign />} />
          <Route path="finished/:id" element={<Finished />} />
          <Route path="nalog/:id" element={<Nalog />} />
          <Route path="receipt/:id" element={<Receipt />} />
          <Route path="messages" element={<Messages />} />

{/*           <Route path="*" element={<NoPage />} />
 */}        </Route>
      </Routes>
    </BrowserRouter>
  )
}
