import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

function Finished () {
  const lg = localStorage.getItem('log')
  if (lg !== 'yes') {
    window.location.href = '/login'
  }
  const { id } = useParams()
  const [amount, setAmount] = useState('')
  const [note, setNote] = useState('')
  const [status, setStatus] = useState('10')

  const handleChange = (event) => {
    setStatus(event.target.value)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    async function getText (file) {
      const x = await fetch(file)
      const y = await x.text()
      alert(y)
    }
    getText(`https://for-corporates.jamtransfer.com/backend/finished.php?id=${id}&cash=${amount}&finalnote=${note}&status=${status}`)
  }
  async function getUsers () {
    const url =
      'https://for-corporates.jamtransfer.com/backend/TransferDetails.php?detailsid=' +
      id
    try {
      const res = await fetch(url)
      return await res.json()
    } catch (error) {
      console.log(error)
    }
  }
  async function renderUsers () {
    const users = await getUsers()
    let html = ''
    users.forEach((user) => {
      const htmlSegment = `<div class="user">
                            <div>PaxName: ${user.PaxName}</div>
                            <div>SubFinalNote: ${user.SubFinalNote}</div>
                            <div>CashIn: ${user.CashIn}</div>
                        </div>`
      localStorage.setItem('temp', user.PickupDate)

      html += htmlSegment
    })

    const container = document.querySelector('.container')
    container.innerHTML = html
  }

  renderUsers()
  return (
    <div>
      <div className="text-center container"></div>
      <form onSubmit={handleSubmit}>
        <h3 className="text-center">Status</h3>
        <select
          value={status}
          onChange={handleChange}
          className="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option selected>Open this select menu</option>
          <option value="10">Completed pad</option>
          <option value="20">Completed not paid</option>
          <option value="30">No Show</option>
          <option value="40">Driver error</option>
        </select>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Amount
          </label>
          <input
            type="number"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Note
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputPassword1"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  )
}

export default Finished
