import React from 'react'
import { useParams } from 'react-router-dom'

function Nalog () {
  const lg = localStorage.getItem('log')
  if (lg !== 'yes') {
    window.location.href = '/login'
  }
  const userid = localStorage.getItem('AuthUserID')
  if (userid < 10) {
    window.location.href = '/'
  }
  const { id } = useParams()
  const ownerid = localStorage.getItem('DriverID')
  async function getText (file) {
    const x = await fetch(file)
    const y = await x.text()
    document.getElementById('demo').innerHTML = y
  }
  getText(`https://for-travel-agencies.jamtransfer.com/backend/Nalog.php?id=${id}&ownerid=${ownerid}`)
  return (
        <div>
            <div id={'demo'}></div>
            <div><a href={`https://for-corporates.jamtransfer.com/backend/putninalog.php?id=${id}&OwnerID=${ownerid}`}>Download</a>
</div>
            <div><button onClick={window.print}>Print this page</button>
</div>
        </div>
  )
}

export default Nalog
